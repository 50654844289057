const footerData = {
  danish: {
    labels: {
      contact: "Kontakt os",
      phone: "Tlf:",
      email: "Email:",
      cvrNumber: "CVR-nummer:",
      openingHours: "Åbningstider",
      weekdays: "Mandag til Fredag:",
      saturday: "Lørdag:",
      sunday: "Søndag:",
      address: "Adresse",
      credits: "Udviklet og designet af",
    },
    contact: {
      phone: "+45 42 79 55 54",
      email: "gronrenssyning@gmail.com",
      cvrNumber: "43001442",
    },
    openingHours: {
      weekdays: "10 - 18",
      saturday: "10 - 15",
      sunday: "Lukket",
    },
    address: {
      street: "Frederikssundsvej 204,",
      city: "2700 København,",
      country: "Danmark",
    },
    credits: "Udviklet og designet af",
    rightsReserved: " 2023 Grøn Rens & Syning / Alle Rettigheder Forbeholdes",
  },
  english: {
    labels: {
      contact: "Contact Us",
      phone: "Phone:",
      email: "Email:",
      cvrNumber: "CVR Number:",
      openingHours: "Opening Hours",
      weekdays: "Monday to Friday:",
      saturday: "Saturday:",
      sunday: "Sunday:",
      address: "Address",
      credits: "Developed and designed by",
    },
    contact: {
      phone: "+45 42 79 55 54",
      email: "gronrenssyning@gmail.com",
      cvrNumber: "43001442",
    },
    openingHours: {
      weekdays: "10am - 6pm",
      saturday: "10am - 3pm",
      sunday: "Closed",
    },
    address: {
      street: "Frederikssundsvej 204,",
      city: "2700 Copenhagen,",
      country: "Denmark",
    },
    credits: "Developed and designed by",
    rightsReserved: " 2023 Grøn Rens & Syning / All Rights Reserved",
  },
};

export default footerData;
