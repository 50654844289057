import NavbarMenu from "./NavbarMenu";
import LanguageSelector from "./LanguageSelector";

import "./Navbar.css";

const Navbar = () => {
  return (
    <header className="main-navigation">
      <LanguageSelector />
      <NavbarMenu />
    </header>
  );
};

export default Navbar;
