import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/UI/ScrollToTop";
import LoadingSpinner from "./components/UI/LoadingSpinner";

const LaundryPage = lazy(() => import("./pages/LaundryPage"));
const TailoringPage = lazy(() => import("./pages/TailoringPage"));
const BasketPage = lazy(() => import("./pages/BasketPage"));

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<LoadingSpinner asOverlay />}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} exact={true} />
          <Route path="/renseri" element={<LaundryPage />} />
          <Route path="/syning" element={<TailoringPage />} />
          <Route path="/kurv" element={<BasketPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
