import { useLanguage } from "../../../context/language-context";
import danishFlagIcon from "../../../assets/danish-flag-icon.png";
import britishFlagIcon from "../../../assets/british-flag-icon.png";

import "./LanguageSelector.css";

const LanguageSelector = () => {
  const { selectedLanguage, changeLanguage } = useLanguage(); // Use the useLanguage hook to access context values
  return (
    <div className="language-selection">
      <ul>
        <li onClick={() => changeLanguage("danish")} title="Danish">
          <img
            src={danishFlagIcon}
            alt="Danish Flag"
            className={selectedLanguage === "danish" ? "active" : ""}
          />
        </li>
        <li onClick={() => changeLanguage("english")} title="English">
          <img
            src={britishFlagIcon}
            alt="British Flag"
            className={selectedLanguage === "english" ? "active" : ""}
          />
        </li>
      </ul>
    </div>
  );
};

export default LanguageSelector;
