import homeIcon from "../assets/nav-link-icons/home-icon.png";
import laundryIcon from "../assets/nav-link-icons/laundry-icon.png";
import sewIcon from "../assets/nav-link-icons/sew-icon.png";

const navbarMenuData = [
  {
    key: "home",
    text: {
      danish: "HJEM",
      english: "HOME",
    },
    logo: homeIcon,
    route: "/",
  },
  {
    key: "laundry",
    text: {
      danish: "RENSERI",
      english: "LAUNDRY",
    },
    logo: laundryIcon,
    route: "/renseri",
  },
  {
    key: "tailoring",
    text: {
      danish: "SYNING",
      english: "TAILORING",
    },
    logo: sewIcon,
    route: "/syning",
  },
];

export default navbarMenuData;
