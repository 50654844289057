import { useLanguage } from "../../context/language-context";
import { heroContentData } from "../../data/homePageData";
import logoImg from "../../assets/logo.png";
import "./Hero.css";

const Hero = ({ language }) => {
  const { selectedLanguage } = useLanguage();

  const { heroTitle, heroSubtitle, heroDeliveryInfo, heroOrderingInfo } =
    heroContentData[selectedLanguage];

  return (
    <section id="hero">
      <div className="hero-intro">
        <img className="logo" src={logoImg} alt="Logo" />
        <h1>{heroTitle}</h1>
        <p>{heroSubtitle}</p>
      </div>
      <div className="order-info">
        <p>{heroOrderingInfo}</p>
        <p>&</p>
        <p className="hero-delivery-info">{heroDeliveryInfo}</p>
      </div>
    </section>
  );
};

export default Hero;
