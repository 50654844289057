import React from "react";
import ReactDom from "react-dom";
import "./Backdrop.css";

// Component for rendering a backdrop, typically used for closing modal or mobile menu
const Backdrop = ({ onClick }) => {
  return ReactDom.createPortal(
    <div className="backdrop" onClick={onClick}></div>,
    document.getElementById("backdrop-hook")
  );
};

export default Backdrop;
