import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../../../context/language-context";
import { useShop } from "../../../context/shop-context";
import basketIcon from "../../../assets/basket-icon.png";
import navbarMenuData from "../../../data/navbarMenuData";
import Backdrop from "../../UI/Backdrop";
import "./NavbarMenu.css";

const NavbarMenu = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [animateBasket, setAnimateBasket] = useState(false);
  const { selectedLanguage } = useLanguage();
  const { getTotalCartItems } = useShop();

  useEffect(() => {
    if (getTotalCartItems() > 0) {
      setAnimateBasket(true);
      const timer = setTimeout(() => setAnimateBasket(false), 500); // Duration of the animation
      return () => clearTimeout(timer);
    }
  }, [getTotalCartItems]);

  const toggleMobileMenuHandler = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const closeMobileMenuHandler = () => {
    setShowMobileMenu(false);
  };

  return (
    <nav className="menu">
      <div className={`basket-container ${animateBasket ? "animate-pop" : ""}`}>
        <NavLink to="/kurv" onClick={closeMobileMenuHandler}>
          <img src={basketIcon} alt="Basket" />
          <span className="item-count">{getTotalCartItems()}</span>
        </NavLink>
      </div>
      <div
        className={`mobile-menu-toggle ${showMobileMenu ? "open" : ""}`}
        onClick={toggleMobileMenuHandler}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={showMobileMenu ? "mobile-menu open" : "mobile-menu"}>
        {navbarMenuData.map((navLink) => (
          <li key={navLink.key} onClick={closeMobileMenuHandler}>
            <NavLink
              to={navLink.route}
              className={(navData) =>
                navData.isActive ? "active-style" : "nav-link"
              }
              exact={navLink.key === "home" ? "true" : "false"}
            >
              <img src={navLink.logo} alt={navLink.key} />
              <div className="nav-item">
                <span>{navLink.text[selectedLanguage]}</span>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
      {showMobileMenu && <Backdrop onClick={closeMobileMenuHandler} />}
    </nav>
  );
};

export default NavbarMenu;
