import { useLanguage } from "../../../context/language-context";
import logo from "../../../assets/logo.png";
import footerData from "../../../data/footerData";

import "./Footer.css";

const Footer = () => {
  const { selectedLanguage } = useLanguage();
  const { labels, contact, openingHours, address, credits, rightsReserved } =
    footerData[selectedLanguage];

  return (
    <footer className="footer-container">
      <div className="logo-section">
        <img src={logo} alt="Grøn Rens & Syning Logo" className="footer-logo" />
        <h3 className="footer-brand">
          Grøn Rens & Syning <span className="brand-dot">.</span>
        </h3>
      </div>
      <div className="info-section">
        <div className="contact">
          <h4>{labels.contact}</h4>
          <p>
            <span className="label">{labels.phone}</span>
            <a href="tel:+45795554"> {contact.phone} </a>
          </p>
          <p>
            <span className="label">{labels.email}</span>{" "}
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </p>
          <p>
            <span className="label">{labels.cvrNumber}</span>{" "}
            {contact.cvrNumber}
          </p>
        </div>
        <div className="opening-hours">
          <h4>{labels.openingHours}</h4>
          <p>
            <span className="label">{labels.weekdays}</span>{" "}
            {openingHours.weekdays}
          </p>
          <p>
            <span className="label">{labels.saturday}</span>{" "}
            {openingHours.saturday}
          </p>
          <p>
            <span className="label">{labels.sunday}</span> {openingHours.sunday}
          </p>
        </div>
        <div className="address">
          <h4>{labels.address}</h4>
          <p>
            {address.street},<span> {address.city},</span> {address.country}
          </p>
        </div>
      </div>
      <div className="credits">
        <h4>{credits}</h4>
        <a href="https://khaibarsaadat.com/" target="__blank">
          Khaibar Saadat
        </a>

        <p className="copyright">&copy;{rightsReserved}</p>
      </div>
    </footer>
  );
};

export default Footer;
