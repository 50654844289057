import galleryImage1 from "../../assets/gallery-images/gallery-1.jpeg";
import galleryImage2 from "../../assets/gallery-images/gallery-2.jpeg";
import galleryImage3 from "../../assets/gallery-images/gallery-3.jpeg";
import galleryImage4 from "../../assets/gallery-images/gallery-4.jpeg";
import galleryImage5 from "../../assets/gallery-images/gallery-5.jpeg";
import galleryImage6 from "../../assets/gallery-images/gallery-6.jpeg";

import { useLanguage } from "../../context/language-context";
import SectionTitle from "../UI/SectionTitle";

import "./Gallery.css";

const Gallery = () => {
  const { selectedLanguage } = useLanguage();

  return (
    <section className="gallery">
      <hr />
      <SectionTitle
        className="gallery-title"
        title={selectedLanguage === "english" ? "Gallery" : "Galleri"}
      />
      <div className="gallery-items">
        <div className="gallery-item">
          <img
            src={galleryImage1}
            alt="Grøn Vaskeri og Skrædderi facade"
            className="gallery-img"
          />
        </div>
        <div className="gallery-item">
          <img
            src={galleryImage2}
            alt="Indvendig af Grøn Vaskeri og Skrædderi"
            className="gallery-img"
          />
        </div>
        <div className="gallery-item">
          <img
            src={galleryImage3}
            alt="Kundeservice på Grøn Vaskeri og Skrædderi"
            className="gallery-img"
          />
        </div>
        <div className="gallery-item">
          <img
            src={galleryImage4}
            alt="Vaskeprocessen på Grøn Vaskeri og Skrædderi"
            className="gallery-img"
          />
        </div>
        <div className="gallery-item">
          <img
            src={galleryImage5}
            alt="Tøjreparation hos Grøn Vaskeri og Skrædderi"
            className="gallery-img"
          />
        </div>
        <div className="gallery-item">
          <img
            src={galleryImage6}
            alt="Personalet på Grøn Vaskeri og Skrædderi"
            className="gallery-img"
          />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
