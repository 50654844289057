import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

const ShopContext = createContext();

export const ShopProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addLaundryToCart = (item) => {
    if (
      item.hasOwnProperty("id") &&
      item.hasOwnProperty("name") &&
      item.hasOwnProperty("price")
    ) {
      const existingLaundryItemIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === item.id && cartItem.type === "laundry"
      );
      if (existingLaundryItemIndex !== -1) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[existingLaundryItemIndex] = {
          ...updatedCartItems[existingLaundryItemIndex],
          quantity: updatedCartItems[existingLaundryItemIndex].quantity + 1,
        };
        setCartItems(updatedCartItems);
      } else {
        setCartItems([...cartItems, { type: "laundry", ...item, quantity: 1 }]);
      }
    } else {
      console.error("Invalid item type:", item);
    }
  };

  const removeLaundryFromCart = (id) => {
    const existingLaundryItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === id && cartItem.type === "laundry"
    );
    if (existingLaundryItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      if (updatedCartItems[existingLaundryItemIndex].quantity > 1) {
        updatedCartItems[existingLaundryItemIndex].quantity--;
      } else {
        updatedCartItems.splice(existingLaundryItemIndex, 1);
      }
      setCartItems(updatedCartItems);
    }
  };

  const addTailoringToCart = ({ id, title, service }) => {
    if (title && service && service.name && service.price) {
      const existingTailoringItemIndex = cartItems.findIndex(
        (cartItem) =>
          cartItem.title === title &&
          cartItem.type === "tailoring" &&
          cartItem.service &&
          cartItem.service.name === service.name
      );
      if (existingTailoringItemIndex !== -1) {
        const updatedCartItems = [...cartItems];
        const existingItem = updatedCartItems[existingTailoringItemIndex];
        existingItem.service.quantity++;
        setCartItems(updatedCartItems);
      } else {
        setCartItems([
          ...cartItems,
          {
            id,
            title,
            type: "tailoring",
            service: { ...service, quantity: 1 },
          },
        ]);
      }
    } else {
      console.error("Invalid parameters for addToTailoringCart");
    }
  };

  const removeTailoringFromCart = (title, serviceName) => {
    const existingItemIndex = cartItems.findIndex(
      (cartItem) =>
        cartItem.title === title &&
        cartItem.type === "tailoring" &&
        cartItem.service &&
        cartItem.service.name === serviceName
    );
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      const existingItem = updatedCartItems[existingItemIndex];
      if (existingItem.service.quantity > 1) {
        existingItem.service.quantity--;
      } else {
        updatedCartItems.splice(existingItemIndex, 1);
      }
      setCartItems(updatedCartItems);
    }
  };

  const getTotalLaundryCartAmount = () => {
    return cartItems.reduce(
      (total, item) =>
        item.type === "laundry"
          ? total + parseFloat(item.price) * item.quantity
          : total,
      0
    );
  };

  const getTotalTailoringCartAmount = () => {
    return cartItems.reduce(
      (total, item) =>
        item.type === "tailoring"
          ? total + parseFloat(item.service.price) * item.service.quantity
          : total,
      0
    );
  };

  const totalCartAmount =
    getTotalLaundryCartAmount() + getTotalTailoringCartAmount();

  const getTotalCartItems = () => {
    return cartItems.length;
  };

  // Function to format laundry items, similar to tailoring items
  const formatLaundryItem = (item) => {
    return {
      id: item.id,
      type: "laundry",
      title: "laundry",
      service: {
        name: item.name,
        price: item.price,
        quantity: item.quantity || 1,
      },
    };
  };

  // Format the cartItems array
  const formatedCartItems = cartItems.map((item) => {
    if (item.type === "laundry") {
      return formatLaundryItem(item);
    } else {
      return item;
    }
  });

  return (
    <ShopContext.Provider
      value={{
        cartItems,
        setCartItems,
        formatedCartItems,
        addLaundryToCart,
        removeLaundryFromCart,
        addTailoringToCart,
        removeTailoringFromCart,
        totalCartAmount,
        getTotalCartItems,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  return useContext(ShopContext);
};
