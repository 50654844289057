import "./AboutCompanyItem.css";

const AboutCompanyItem = ({ image, imageAttribute, title, description }) => {
  return (
    <article className="company-item">
      <div className="about-company-subtitle">
        <img src={image} alt={imageAttribute} />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </article>
  );
};

export default AboutCompanyItem;
