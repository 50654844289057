import aboutCompanyImg from "../assets/company-about.png";
import laundryTailorImg from "../assets/company-laundry-tailor.png";
import ecoFriendlyImg from "../assets/company-eco.png";
import deliverImg from "../assets/company-delivery.png";
import orderImg from "../assets/company-order.png";

export const heroContentData = {
  danish: {
    heroTitle: "Velkommen til Grøn Rens & Syning",
    heroSubtitle: "Dit valg til vask & skrædderi i København!",
    heroDeliveryInfo:
      "Vi tilbyder afhentning og levering for kun 25 kr. pr. vej.",
    heroOrderingInfo: "Brug vores nemme online bestilling.",
  },
  english: {
    heroTitle: "Welcome to Green Laundry & Tailoring",
    heroSubtitle: "Your go-to for laundry & tailoring in Copenhagen!",
    heroDeliveryInfo: "We offer pick-up and delivery for just 25 kr each way.",
    heroOrderingInfo: "With Easy Ordering",
  },
};

export const aboutCompanyContentData = {
  danish: {
    title: "Vores Virksomhed",
    items: [
      {
        image: aboutCompanyImg,
        imageAttribute: "Om Os",
        title: "Om Os",
        description:
          "Hos Grøn Rens & Syning sørger vi altid for at udføre vores arbejde med fokus på kvalitet. Vi er dedikerede til at holde os opdateret med de nyeste udviklinger inden for renseteknikker og teknologi. Vi bruger f.eks. en unik dry-clean maskine, hvilket giver os et solidt grundlag for at kunne håndtere forskellige udfordringer.",
      },
      {
        image: laundryTailorImg,
        imageAttribute: "Renseri & Skrædderi i Brønshøj",
        title: "Renseri & Skrædderi",
        description:
          "Du er altid velkommen til at besøge vores renseri & skrædderi i Brønshøj. Vi tilbyder en bred vifte af services, lige fra rensning af tøj til reparation og skrædderarbejde. Uanset hvad du har brug for, står vi klar til at hjælpe dig med dine tekstiler og beklædningsgenstande.",
      },
      {
        image: ecoFriendlyImg,
        imageAttribute: "Miljørigtig Rensning",
        title: "Miljørigtig Rensning",
        description:
          "Vi tager vores ansvar for miljøet alvorligt. Samtidig med at vi opretholder vores høje standarder for kvalitet og service, stræber vi efter at reducere vores miljøpåvirkning. Vi implementerer løbende nye metoder og teknikker for at fremme bæredygtighed i vores tjenester og bidrage til en mere bæredygtig fremtid.",
      },
      {
        image: deliverImg,
        imageAttribute: "Hente & Bring Service",
        title: "Hente & Bring",
        description:
          "Som din skrædder tilbyder vi en praktisk hente og bringe service. Uanset om du har travlt eller mangler muligheden for at komme forbi vores butik inden for åbningstiderne, kan vi hjælpe. Vi tilbyder fri parkering ved afhentning og levering af tøj og dækker København og Storkøbenhavn.",
      },
      {
        image: orderImg,
        imageAttribute: "Bestil Rens eller Skrædder Online",
        title: "Bestiling Online",
        description:
          "For at gøre det så nemt som muligt for dig, tilbyder vi online bestilling af rensning og skrædderarbejde. Vores hjemmeside gør det let at bestille, og med vores hurtige service kan du have dit tøj klar til brug inden for 48 timer. Spar tid og besvær ved at få dit tøj leveret direkte til dig.",
      },
    ],
  },
  english: {
    title: "Our Company",
    items: [
      {
        image: aboutCompanyImg,
        imageAttribute: "About Us",
        title: "About Us",
        description:
          "At Green Laundry & Tailoring, we always ensure to carry out our work with a focus on quality. We are dedicated to staying updated with the latest developments in cleaning techniques and technology. For instance, we use a unique dry-clean machine, which provides us with a solid foundation to handle various challenges.",
      },
      {
        image: laundryTailorImg,
        imageAttribute: "Laundry & Tailoring in Brønshøj",
        title: "Laundry & Tailoring",
        description:
          "You are always welcome to visit our laundry & tailoring shop in Brønshøj. We offer a wide range of services, from clothing cleaning to repairs and tailoring. Whatever you need, we are ready to assist you with your textiles and garments.",
      },
      {
        image: ecoFriendlyImg,
        imageAttribute: "Eco-Friendly Cleaning",
        title: "Eco-Friendly Cleaning",
        description:
          "We take our responsibility for the environment seriously. While maintaining our high standards of quality and service, we strive to reduce our environmental impact. We continuously implement new methods and techniques to promote sustainability in our services and contribute to a more sustainable future.",
      },
      {
        image: deliverImg,
        imageAttribute: "Pickup & Delivery Service",
        title: "Pickup & Delivery",
        description:
          "As your tailor, we offer a convenient pickup and delivery service. Whether you are busy or unable to visit our shop during opening hours, we can help. We offer free parking for pickup and delivery of clothes and cover Copenhagen and Greater Copenhagen.",
      },
      {
        image: orderImg,
        imageAttribute: "Order Laundry or Tailoring Online",
        title: "Online Ordering",
        description:
          "To make it as easy as possible for you, we offer online ordering of cleaning and tailoring. Our website makes it easy to order, and with our fast service, you can have your clothes ready to use within 48 hours. Save time and hassle by having your clothes delivered directly to you.",
      },
    ],
  },
};
