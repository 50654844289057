import React from "react";

import Hero from "../components/home/Hero";
import AboutCompany from "../components/home/AboutCompany";
import Gallery from "../components/home/Gallery";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <AboutCompany />
      <Gallery />
    </div>
  );
};

export default HomePage;
