import React from "react";
import SectionTitle from "../UI/SectionTitle";
import AboutCompanyItem from "./AboutCompanyItem";
import { aboutCompanyContentData } from "../../data/homePageData"; // Assuming you have exported contentData from another file
import { useLanguage } from "../../context/language-context";
import "./AboutCompany.css";

const AboutCompany = () => {
  const { selectedLanguage } = useLanguage();
  const content = aboutCompanyContentData[selectedLanguage];

  return (
    <section className="about-company-container">
      <SectionTitle title={content.title} />
      <div className="company-info">
        {content.items.map((item, index) => (
          <AboutCompanyItem
            key={index}
            image={item.image}
            imageAttribute={item.imageAttribute}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default AboutCompany;
